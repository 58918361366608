import { useEffect } from 'react';

import { selectConfigServiceFeatureFlags } from '@/ducks/common/settings';
import { useAppSelector } from '@/store';

import { loadUplift } from './adapter';
import { selectRelevantCurrencyCode, selectUpliftConfig } from './selectors';

export const useInitUplift = () => {
  const isEnabled = Boolean(useAppSelector(selectConfigServiceFeatureFlags)?.uplift);
  const config = useAppSelector(selectUpliftConfig);
  const currencyCode = useAppSelector(selectRelevantCurrencyCode);

  useEffect(() => loadUplift({ config, currencyCode, isEnabled }), [config, currencyCode, isEnabled]);
};
