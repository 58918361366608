import { useEffect } from 'react';

import isEqual from 'lodash/isEqual';
import { useStore } from 'react-redux';

import type { AppStore, RootState } from '@/store';

import { SESSION_SKIPPED_KEYS } from '@/constants/settings';
import aligners from '@/constants/storePersistentAligners';
import { setIsPersistentReady } from '@/ducks/common';

import { getStorage, getValue, hasStorages, setValue } from './helpers/accessors';
import cleanup from './helpers/cleanup';
import SessionStorageEvents from './helpers/SessionStorageEvents';

const useStorePersistentAligners = () => {
  const { dispatch, getState, subscribe } = useStore<RootState>() as AppStore;

  useEffect(() => {
    if (hasStorages()) {
      for (const { action, inSession, selector, storagePath } of aligners) {
        const next = getValue(storagePath, inSession);
        const prev = cleanup(selector(getState()));
        if (next !== undefined && !isEqual(prev, next)) dispatch(action(next));
      }

      dispatch(setIsPersistentReady());

      const onStorage = ({ key, storageArea }: StorageEvent) => {
        for (const { action, inSession, selector, storagePath } of aligners) {
          if (storageArea === getStorage(inSession)) {
            if ((Array.isArray(storagePath) ? storagePath[0] : storagePath) === key) {
              const next = getValue(storagePath, inSession);
              const prev = cleanup(selector(getState()));
              if (!isEqual(prev, next)) dispatch(action(next));
            }
          }
        }
      };

      const onRedux = () => {
        const state = getState();
        for (const { inSession, selector, storagePath } of aligners) {
          const next = cleanup(selector(state));
          const prev = getValue(storagePath, inSession);
          if (!isEqual(prev, next)) {
            setValue(storagePath, next, inSession);
          }
        }
      };

      const unsubscribe = subscribe(onRedux);
      window.addEventListener('storage', onStorage);
      SessionStorageEvents.lockKey(...SESSION_SKIPPED_KEYS);
      SessionStorageEvents.on(onStorage);
      return () => {
        window.removeEventListener('storage', onStorage);
        SessionStorageEvents.off(onStorage);
        SessionStorageEvents.unlockKey(...SESSION_SKIPPED_KEYS);
        unsubscribe();
      };
    }
  }, []);
};

export default useStorePersistentAligners;
