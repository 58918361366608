import { addDays } from 'date-fns';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { type PaymentType } from '@/containers/VoyagePlanner/Payment/PaymentOption/types';
import { type TConfigServiceUplift } from '@/ducks/common/settings';
import { getSearchParams } from '@/ducks/routes/history';
import { type TSailorInfoWithInsurance } from '@/ducks/travelParty/selectors';
import { type TSailorInfo } from '@/ducks/travelParty/types';
import { selectUpliftSupportedCurrencies } from '@/features/uplift/selectors';
import { isServerSide } from '@/helpers/isServerSide';
import { format, formatDate, parse } from '@/helpers/util/dateUtil';
import { getSessionStorageValue, removeSessionStorageValue, setSessionStorageValue } from '@/helpers/util/storage';
import { type TCurrencyCode, type TISODate } from '@/infra/types/common';
import { type TSailingData } from '@/infra/types/voyageInfo/sailing';
import { rootStore } from '@/store';

import load from './load';
import { clear, setIsEligible, setIsReady } from './slice';
import {
  type TUpliftInsurance,
  type TUpliftItinerary,
  type TUpliftLoadScript,
  type TUpliftPaymentsInitArgs,
  type TUpliftResponse,
  type TUpliftResponseStatus,
  type TUpliftTraveler,
  type TUpliftWindow,
} from './types';

// This is not an exhaustive list of supported currencies, it is only a map of matching currencies and languages.
// The list of supported currencies is determined by data from the /settings endpoint
const UPLIFT_LOCALES = { CAD: 'en-CA', default: 'en-US', USD: 'en-US' } as const;

const UPLIFT_URL = '//cdn.uplift-platform.com/a/up.js';
const UPLIFT_ELIGIBLE = 'isUpliftEligible';

const upliftOnChangeCallback = (response: TUpliftResponse) => {
  const { dispatch } = rootStore!;
  const statusHandlers = {
    OFFER_AVAILABLE: () => {
      setSessionStorageValue(UPLIFT_ELIGIBLE, true);
      dispatch(setIsEligible(true));
    },
    OFFER_UNAVAILABLE: () => {
      setSessionStorageValue(UPLIFT_ELIGIBLE, false);
      dispatch(setIsEligible(false));
    },
    SERVICE_UNAVAILABLE: () => {
      removeSessionStorageValue(UPLIFT_ELIGIBLE);
      dispatch(setIsEligible(false));
    },
    TOKEN_AVAILABLE: () => {},
    TOKEN_RETRIEVED: () => {},
  } as Record<TUpliftResponseStatus, () => void>;
  statusHandlers[response.status]();
};

export type TLoadUpliftArgs = {
  config: TConfigServiceUplift;
  currencyCode: TCurrencyCode;
  isEnabled: boolean;
};

export const loadUplift = ({ config, currencyCode, isEnabled }: TLoadUpliftArgs) => {
  const { apiKey, upCodes } = config || {};
  const upcode = upCodes?.[currencyCode];
  if (isEnabled && apiKey && upcode && isCurrencySupported(currencyCode)) {
    loadUpliftPaymentsJs(currencyCode, upcode, () => {
      initUpliftPayments({
        apiKey,
        currency: currencyCode,
        onChange: upliftOnChangeCallback,
        upcode,
      });
    });
  } else {
    removeSessionStorageValue(UPLIFT_ELIGIBLE);
    removeUpliftJs();
  }
};

const getUpliftJsNodes = (upcode?: string) =>
  [...document.scripts].filter(({ src }) => src.includes(UPLIFT_URL) && (!upcode || src.includes(upcode)));

const removeUpliftJs = () => {
  const { dispatch } = rootStore!;
  const win = window as TUpliftWindow;
  if (!isServerSide() && (win.upReady || win.Uplift)) {
    dispatch(clear());
    const { Payments } = win.Uplift || {};
    if (Payments) {
      Payments.clear();
      Payments.exit();
    }
    ['up', 'upReady', 'Uplift', 'UpLift', 'UpLiftPlatformObject', '_upliftFlag'].forEach((name) => {
      delete win[name as keyof TUpliftWindow];
    });

    getUpliftJsNodes().forEach((script) => {
      script.remove();
    });
  }
};

export const loadUpliftPaymentsJs = (currencyCode: TCurrencyCode, upCode: string, callback: () => void) =>
  new Promise<void>((resolve, reject) => {
    const { dispatch } = rootStore!;
    const onError = (err: unknown) => {
      reject(err);
      dispatch(clear());
    };
    try {
      const timeout = setTimeout(() => {
        reject(new Error('uflift init timeout'));
      }, 60000);

      removeUpliftJs();
      const win = window as TUpliftWindow;
      win.upReady = async () => {
        clearTimeout(timeout);
        if (typeof callback === 'function') {
          try {
            await callback();
          } catch (e) {
            onError(e);
            return;
          }
        }
        resolve();
        dispatch(setIsReady(currencyCode));
      };
      (load as TUpliftLoadScript)(win, document, 'script', UPLIFT_URL, 'up', upCode);
      const nodes = getUpliftJsNodes(upCode);
      nodes[nodes.length - 1]!.onerror = onError;
    } catch (e) {
      onError(e);
    }
  });

export const isCurrencySupported = (currencyCode: TCurrencyCode) => {
  const supportedCurrencies = selectUpliftSupportedCurrencies(rootStore!.getState());
  return supportedCurrencies.includes(currencyCode);
};

export const initUpliftPayments = (args: Omit<TUpliftPaymentsInitArgs, 'locale'>) => {
  const { currency } = args;
  const locale = UPLIFT_LOCALES[currency as keyof typeof UPLIFT_LOCALES] || UPLIFT_LOCALES.default;
  (window as TUpliftWindow).Uplift!.Payments.init({ ...args, currency: currency || 'USD', locale });
};

const prepareSailingObjectForUplift = (sailingData: TSailingData) => {
  if (isEmpty(sailingData)) return;

  const ports = sailingData.itinerary || sailingData.ports;
  return {
    ports: ports.map((port) => ({
      day: port.itineraryDay || port.day,
      name: port.portName || port.name,
    })),
    startDate: sailingData.embarkDate || sailingData.startDate,
  };
};

const formatTripInfoDate = (date: TISODate, dayNumber: number = 0) =>
  format(addDays(parse(date)!, dayNumber - 1), 'yyyyMMdd') as string;

const getTravelerInfo = (sailor: TUpliftTraveler, id: number) => {
  const { dob, firstname, lastname } = sailor;
  return {
    date_of_birth: (formatDate(dob, 'MM/dd/yyyy') as string) || null,
    first_name: firstname,
    id,
    last_name: lastname,
  };
};

export const buildTripInfo = (
  price: number,
  insurancePrice: number,
  sailing: TSailingData,
  sailorDetails: TSailorInfoWithInsurance,
  cabinName?: string,
  additionalSailors?: TSailorInfo[],
) => {
  const { ports, startDate } = prepareSailingObjectForUplift(sailing) || {};
  const travelers = [];
  let billing_contact = {};
  const itinerary = (ports || []).reduce<TUpliftItinerary[]>((acc, port, index, src) => {
    if (index < src.length - 1) {
      const arrivalPort = src[index + 1]!;
      acc.push({
        arrival_date: formatTripInfoDate(startDate!, arrivalPort.day),
        arrival_port: arrivalPort.name,
        departure_date: formatTripInfoDate(startDate!, port.day),
        departure_port: port.name,
      });
    }
    return acc;
  }, [] as TUpliftItinerary[]);
  const { cabinType, shipCode } = getSearchParams();
  const suiteName = getSessionStorageValue('suiteName');
  const cruiseInfo = {
    cruise_duration: get(sailing, 'duration', ''),
    cruise_line: 'Virgin Voyages',
    disembark_date: formatDate(get(sailing, 'debarkDate', ''), 'yyyyMMdd') as string,
    embark_date: formatDate(get(sailing, 'embarkDate', ''), 'yyyyMMdd') as string,
    insurance: [] as TUpliftInsurance[],
    itinerary,
    rooms: [{ cabin_type: suiteName, state_room_type: cabinName || cabinType }],
    ship_code: get(sailing, 'ship.code', shipCode),
  };
  if (sailorDetails) {
    const { city, contactnumber, country, dob, email, firstname, lastname, postalCode, stateCode, streetAddress } =
      sailorDetails;
    if (firstname && lastname) {
      travelers.push(getTravelerInfo({ dob, firstname, lastname }, 0));
    }
    if (additionalSailors && additionalSailors.length > 0) {
      additionalSailors.forEach((sailor, index) => {
        const { dob, firstname, lastname } = sailor;
        if (firstname && lastname) {
          travelers.push(getTravelerInfo({ dob, firstname, lastname }, index + 1));
        }
      });
    }
    billing_contact = {
      date_of_birth: (formatDate(dob, 'MM/dd/yyyy') as string) || null,
      email,
      first_name: firstname,
      last_name: lastname,
      phone: contactnumber,
      ...(sailorDetails.insurance && {
        city,
        country,
        postal_code: postalCode,
        region: stateCode,
        street_address: streetAddress,
      }),
    };
    if (sailorDetails.insurance) {
      cruiseInfo.insurance.push({
        id: '0',
        price: insurancePrice ?? 0,
        types: ['cancellation'],
      });
    }
  }
  return {
    billing_contact,
    cruise_reservations: [cruiseInfo],
    order_amount: Math.ceil(price),
    travelers,
  };
};

export const loadTripInfo = (tripInfo: unknown) => {
  try {
    (window as TUpliftWindow).Uplift?.Payments.load(tripInfo);
  } catch (e) {
    // Do nothing
  }
};

export const selectPaymentMethod = () => {
  try {
    (window as TUpliftWindow).Uplift?.Payments.select();
  } catch (e) {
    // Do nothing
  }
};

export const deselectPaymentMethod = (option: unknown) => {
  try {
    (window as TUpliftWindow).Uplift?.Payments.deselect(option);
  } catch (e) {
    // Do nothing
  }
};

export const getPayOption = (option: PaymentType) => {
  switch (option) {
    case 'DEPOSIT':
    case 'FULL':
    case 'HOLD':
      return option.toLowerCase();
    case 'UPLIFT':
      return 'pay monthly';
    default:
      return 'other';
  }
};

export const setOrderResponse = (paymentOption: PaymentType, transactionId: string) => {
  const option = getPayOption(paymentOption);
  (window as TUpliftWindow).Uplift?.Analytics.orderResponse({
    mode_of_payment: option,
    order_id: transactionId,
  });
};

export const setOrderSubmit = (orderInfo: unknown, paymentOption: PaymentType, availablePayOptions: PaymentType[]) => {
  const option = getPayOption(paymentOption);
  (window as TUpliftWindow).Uplift?.Analytics.orderSubmit(orderInfo, {
    mode_of_payment: option,
    payment_options: availablePayOptions,
  });
};
