import { intersection } from 'lodash';
import { createSelector } from 'reselect';

import type { RootState } from '@/store';
import type { TCurrencyCode } from '@/types';

import { selectSettings, type TConfigServiceUplift } from '@/ducks/common/settings';
import { selectCurrencyCode } from '@/ducks/filters/selectors';
import { selectInvoiceCurrencyCode } from '@/ducks/pages/summary/selectors';
import createMemoSelector from '@/helpers/createMemoSelector';

export const selectUplift = (state: RootState) => state?.uplift;

export const selectUpliftActiveCurrencyCode = (state: RootState) => selectUplift(state)?.activeCurrencyCode;

export const selectUpliftIsStarted = (state: RootState) => !!selectUpliftActiveCurrencyCode(state);

export const selectRelevantCurrencyCode = (state: RootState) =>
  selectInvoiceCurrencyCode(state) || selectCurrencyCode(state);

export const selectUpliftIsReady = (state: RootState) =>
  selectUpliftActiveCurrencyCode(state) === selectRelevantCurrencyCode(state);

export const selectIsUpliftEligible = (state: RootState) => selectUplift(state)?.isEligible;

export const selectUpliftConfig = (state: RootState) => selectSettings(state)?.uplift;
const objectKeys = Object.keys as <R>(arg: R) => Array<keyof R>;

export const selectUpliftSupportedCurrencies = createMemoSelector(
  selectUpliftConfig,
  (data: TConfigServiceUplift): TCurrencyCode[] =>
    intersection(...[data?.maximumPrice, data?.minimumDays, data?.minimumPrice].filter(Boolean).map(objectKeys)).sort(),
);

export const selectUpliftApplicable = createSelector(
  [selectUpliftIsReady, selectUpliftSupportedCurrencies, selectRelevantCurrencyCode],
  (isUpliftReady, currencies, currencyCode) => isUpliftReady && currencies.includes(currencyCode),
);

export const selectUpliftMaximumPrices = (state: RootState) => selectUpliftConfig(state)?.maximumPrice;

export const selectUpliftMinimumPrices = (state: RootState) => selectUpliftConfig(state)?.minimumPrice;

export const selectUpliftMinimumDays = (state: RootState) => selectUpliftConfig(state)?.minimumDays;
